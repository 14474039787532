<template>
  <div class="hero">
    <!--<div class="static-letters">
        <picture>
    <source :srcset="image"     
           media="(min-width: 768px)">
    <img :src="imageMobile" alt="love letters hero image" />
</picture>
    </div>-->

    <div class="hero-lockup">
      <div class="postcard" id="postcard-1">
        <picture>
          <source :srcset="postcard1" media="(min-width: 768px)" />
          <img :src="postcard1" alt="love letters postcard 1" />
        </picture>
      </div>
      <div class="postcard" id="postcard-2">
        <picture>
          <source :srcset="postcard2" media="(min-width: 768px)" />
          <img :src="postcard2" alt="love letters postcard 2" />
        </picture>
      </div>
      <div class="postcard" id="postcard-empty">
        <picture>
          <source :srcset="postcard3" media="(min-width: 768px)" />
          <img :src="postcard3" alt="love letters postcard 3" />
        </picture>
      </div>

      <div class="postcard" id="postcard-3">
        <picture>
          <source :srcset="postcard3" media="(min-width: 768px)" />
          <img :src="postcard3" alt="love letters postcard 3" />
        </picture>
      </div>
      <div class="postcard" id="postcard-4">
        <picture>
          <source :srcset="postcard4" media="(min-width: 768px)" />
          <img :src="postcard4" alt="love letters postcard 4" />
        </picture>
      </div>
      <div class="postcard" id="postcard-5">
        <picture>
          <source :srcset="postcard5" media="(min-width: 768px)" />
          <img :src="postcard5" alt="love letters postcard 5" />
        </picture>
      </div>
      <div class="inner">
        <h1 class="heading1">LoveTravels with Love Letters</h1>
        <p class="p-large">
          Spread Some Love in Support of LGBTQ+ Youth
        </p>
        <div class="stamp">
          
            <img :src="stamp" alt="Postage Stamp" />
  
          <inline-svg
            src="/images/Hero_Images/Stamp_Wave.svg"
            width="80"
            height="80"
            aria-label="Stamp"
          ></inline-svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHero",
  data() {
    return {
      stamp: require("../../public/images/Postcard Art - Write Letter component/Mobile/inactive/card 1 art M.png"),
      postcard1: require("../../public/images/Hero_Images/World.png"),
      postcard2: require("../../public/images/Hero_Images/Hotel.png"),
      postcard3: require("../../public/images/Hero_Images/Polaroid.png"),
      postcard4: require("../../public/images//Hero_Images/Map.png"),
      postcard5: require("../../public/images/Hero_Images/Car.png"),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hero {
  position: relative;
  min-height: 1000px;
  @media (min-width:500px) {
    min-height:1250px;
  }
  .static-letters {
    max-height: 80vh;
    width: 100%;
  }

  .hero-lockup {
    color: black;
    position: relative;
    margin: 100px auto;

    width: 90%;

    text-align: center;
  }

  .inner {
    box-shadow: 0px 0px 5px lightgray;
    background: white;
    position: relative;
    z-index: 1;
    padding: 130px 10vw 90px;
    height: 450px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    max-width: 450px;
    margin: auto;

    .heading1 {
      margin-bottom: 10px;
    }
    @media(max-width:767px) {
      .heading1 {
      font-size: 44px;
      line-height: 51px;
      
    }
    }
    
  }

  .postcard {
    box-shadow: -1px -3px 5px lightgray;
    position: absolute;
    width: 75vw;
  }
  #postcard-1 {
    top: -30vw;
    transform: rotate(-10deg);
    right: 40vw;
  }
  #postcard-2 {
    bottom: -75vw;
    transform: rotate(-8deg);
    left: 42vw;
    z-index: 1;
  }
  #postcard-empty {
    background: #e8d7fe;
    right: -50vw;
    bottom: 350;
    transform: rotate(-30deg);
    img {
      opacity: 0;
    }
  }
  #postcard-3 {
    right: 42vw;
    bottom: -90vw;
    transform: rotate(-25deg);
  }
  #postcard-4 {
    bottom: -44vw;
    transform: rotate(20deg);
    right: 45vw;
    z-index: 2;
  }
  #postcard-5 {
    left: 45vw;
    bottom: -35vw;
    transform: rotate(5deg);
  }

  .stamp {
    position: absolute;
    right: 1vw;
    top: 1vw;
    img {
      position: absolute;
      right: 0;
      width:80px;
    }
    svg {
      position: absolute;
      right: -17px;
      transform: rotate(-30deg);
      top: 30px;

    }
  }

  @media (min-width: 768px) {
    min-height: 65vw;
    .inner {
      max-width: 75vw;
      height: 50vw;
      max-height:500px;
      min-height: 300px;
      padding: 50px 10vw 90px;
    }
    .postcard {
      width: 40vw;
    }
    .postcard img {
      width: 100%;
    }

    #postcard-1 {
      top: -10vw;
      right: 55vw;
      z-index: 1;
    }
    #postcard-2 {
      bottom: -16vw;
      transform: rotate(-8deg);
      left: 56vw;
      z-index: 2;
    }
    #postcard-empty {
      display: none;
    }
    #postcard-3 {
      right: 63vw;
      bottom: -14vw;
      transform: rotate(-25deg);
      z-index: 0;
    }
    #postcard-4 {
      bottom: -21vw;
      transform: rotate(9deg);
      right: 33vw;
    }
    #postcard-5 {
      bottom: unset;
      right: -10vw;
      left: unset;
      top: -10vw;
    }

    .stamp {
    
    img {
      position: absolute;
      right: 0;
      width:100px;
    }
    svg {
      position: absolute;
      right: -40px;
      transform: rotate(-30deg);
      top: 30px;
          width: 10vw;
    max-width: 120px;

    }
  }
  }

  @media (min-width: 1200px) {
    .inner {
      max-width: 50vw;
    }
  }
}
</style>
