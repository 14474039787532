<template>
  <header class="header">
    <div class="inner">
    <div class="header__wrapper">
      <inline-svg :src="logoSrc"></inline-svg>
      <button class="button black" @click="this.$emit('handleOpenModal')">Write Letter</button>
    </div>
    </div>
  </header>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: { InlineSvg },
  data() {
    return {
      logoSrc: require("../../public/images/Logos/Love Travel_Black.svg")
    }
  }
}
</script>

<style scoped lang="scss">
  .header {
    background-color: #ffff;
    border-bottom: 2px solid #000; 
    left: 0;
    right: 0;
    position: fixed;
    top: 0;
    height: 125px;
    z-index: 999;

    .inner {
      height: 100%;
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      margin:0 30px;

      svg {
        width:50%;
        max-width:250px;
      }

    .button {
      margin:0;
    }
    }

  @media (max-width:600px) {
    height:100px;
    .button {
       margin-left: 20px;
    padding: 10px;
    font-size: 13px;
    }
   
  }
   
  }
</style>
