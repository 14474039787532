<template>
 <section class="info-blocks">
     <div class="info-block">
         <div class="info-image">
             <div class="gradient-down"></div>
             <img :src="emiko" alt="Smiling artist Meg Emiko holds out a sticker that reads 'Protect Trans Kids'.">
         </div>
         <div class="info-content">
             <p class="kicker heading3">Artist</p>
            <p class="heading1">
                Meg Emiko
            </p>
            <p class="p-regular">
                Meg is an Asian American trans non-binary artist and activist. 
                They create art, apparel, stickers, and prints to help QTBIPOC and LGBTQIA+ folks feel seen, heard, 
represented,    and loved. Find more of their art on Instagram @megemikoart. 

            </p>
            <a class="button black" href="https://www.instagram.com/megemikoart/?hl=en" target="_blank">Follow Meg</a>

         </div>
     </div>
     <div class="info-block">
         <div class="info-image">
             <div class="gradient-up"></div>
             <img :src="shephard" alt="Matthew Shepard cradles a phone in the nook of his neck.">
         </div>
         <div class="info-content">
             <p class="kicker heading3">philanthropic partner</p>
            <p class="heading1">
               Matthew Shepard Foundation
            </p>
            <p class="p-regular">
                For 20 years, the Matthew Shepard Foundation has worked to foster a more caring and just world. 
                Through local, regional, and national outreach, the Foundation empowers individuals to create change, 
                and challenges communities to accept everyone as they are.
            </p>
            <a href="https://www.matthewshepard.org/" target="_blank" class="button black">Learn More</a>

         </div>
     </div>
      <div class="info-block">
         <div class="info-image">
             <div class="gradient-down"></div>
             <img :src="pflag" alt="Colorful image collage of PFLAG signs and people.">
         </div>
         <div class="info-content">
             <p class="kicker heading3">philanthropic partner</p>
            <p class="heading1">
               PFLAG
            </p>
            <p class="p-regular">
                Founded in 1973, PFLAG is the first and largest organization dedicated to supporting, educating, and advocating for LGBTQ+ people and their families. 
                PFLAG’s network of hundreds of chapters and more than 250,000 members works to create a caring, just, and affirming world for LGBTQ+ people and those who love them. 
            </p>
            <a href="https://pflag.org/" target="_blank" class="button black">Learn More</a>

         </div>
     </div>
     <div class="info-block">
         <div class="info-image">
             <div class="gradient-up"></div>
             <img :src="colors" alt="Sticker that reads 'Diverse Inclusive Accepting Welcoming Safe Space For Everyone'.">
         </div>
         <div class="info-content">
             <p class="kicker heading3">philanthropic partner</p>
            <p class="heading1">
                True Colors United
            </p>
            <p class="p-regular">
                Co-founded in 2008 by Cyndi Lauper, 
                True Colors United develops and implements solutions to youth homelessness 
                that focus on the unique experiences of LGBTQ youth, youth of color, and other highly impacted groups. 
                True Colors United achieves their mission through four key program areas: Training & Education, Advocacy, 
                Youth Action, and Technical Assistance. Together, we can create a world where all young people can be their true selves.
            </p>
            <a href='https://truecolorsunited.org/' target="_blank" class="button black">Learn More</a>

         </div>
     </div>
 </section>
</template>

<script>
export default {
  name: "InfoBlocks",
  props: {
    msg: String,
  },
  data() {
    return {
      emiko: require("../../public/images/Images/Meg Emiko.jpg"),
      shephard: require("../../public/images/Images/MatthewShepard.jpg"),
      pflag: require("../../public/images/Images/PFLAG.jpg"),
      colors: require("../../public/images/Images/TrueColors.jpg"),
      
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
  
    window.addEventListener("resize", function () {
  
      if (window.innerWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }

    });
  },

 
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "@/styles/base/colors.scss";
    .kicker {
        text-transform: uppercase;
    }

    .info-blocks {
        color:black;
        margin: 80px 30px;
        .info-block {display: flex;
        flex-direction: column;
        gap: 40px;
    }
    .info-block {
        margin:80px 0 120px;
        .info-image {
            position:relative;
            height: 0;
            padding-top: (calc(100% - 20px));
            position: relative;

            img {
                position: absolute;
                top: 20px;
                height: 100%;
                width: calc(100% - 20px);
                object-fit: cover;
            }
            
        }
            &:nth-child(2n) {
                .info-image {
                   img { 
                    right:0;
                } 
                }
                
            }
    }
    

    .gradient-down {
        height: 100%;
        width: calc(100% - 20px);
        position: absolute;
        z-index: -1;

        right: 0;
        background: linear-gradient($blue, $blue-purple, $purple, $peach);

        position: absolute;
        top: 0;

    }

    .gradient-up {
        height: 100%;
        width: calc(100% - 20px);
        position: absolute;
        z-index: -1;
        top:0;
        left: 0;
        background: linear-gradient(to top, $blue, $blue-purple, $purple, $peach);
    }

    .heading1 {
       margin-bottom: 20px;
    }

    @media (min-width:768px) {
        .info-blocks {
            margin-left:90px;
            margin-right:90px;
        }
        .info-block {
            
            align-items: center;
            gap: 130px;
            &:nth-child(2n - 1){
                flex-direction: row;
                padding-top: 20px;
            }
            &:nth-child(2n) {
                flex-direction:row-reverse;
            }
            .info-image, .info-content {
                flex:1;
            }

            .info-image {
                padding-top: calc(50% - 85px);
            }
        }
    }
}
</style>
