/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLoveLetter = /* GraphQL */ `
  mutation CreateLoveLetter(
    $input: CreateLoveLetterInput!
    $condition: ModelLoveLetterConditionInput
  ) {
    createLoveLetter(input: $input, condition: $condition) {
      id
      artSelection
      loveLetter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLoveLetter = /* GraphQL */ `
  mutation UpdateLoveLetter(
    $input: UpdateLoveLetterInput!
    $condition: ModelLoveLetterConditionInput
  ) {
    updateLoveLetter(input: $input, condition: $condition) {
      id
      artSelection
      loveLetter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLoveLetter = /* GraphQL */ `
  mutation DeleteLoveLetter(
    $input: DeleteLoveLetterInput!
    $condition: ModelLoveLetterConditionInput
  ) {
    deleteLoveLetter(input: $input, condition: $condition) {
      id
      artSelection
      loveLetter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
