<template>
  <div class="gradient">
    <main-header @handleOpenModal="openModal"></main-header>
    <app-hero></app-hero>
   
    <div class="inner">
      <div class="intro">
        <h2 class="heading1">Join Us & <br> Make Love Travel</h2>
        <p class="heading2"> 
          For every letter sent, Marriott International will make 
          a donation to our philanthropic partners, PFLAG and the Matthew Shepard Foundation.
        </p>
        <img id="heart-image" :src="heart" alt="Rainbow Heart Icon"/>
      </div>
      <write-letter @handleOpenModal="openModal"></write-letter>
      <letter-modal @handleCloseModal="closeModal" :modalIsOpen="modalIsOpen"></letter-modal>
    </div>
  </div>
  <div class="inner">
    <info-blocks></info-blocks>
  </div>
  <wall-papers></wall-papers>
   <terms-conditions v-if="terms" @clicked="terms = false"></terms-conditions>
  <footer>
    <div class="inner">
      <div class="flex">
        <div class="footer-logo">
          <img :src="logo" alt="Love Travels by Marriott International Logo"/>
        </div>
        <div class="social-share-icons">
          <p class="p-large">Follow Us</p>
          <div class="social-icons">
            <a href="https://www.facebook.com/marriottinternational/" target="_blank" class="icon">
              <span>
                <inline-svg src="/images/social icons/Facebook.svg" width="45" height="45" fill="black"
                  aria-label="My image"></inline-svg>
              </span>
            </a>
            <a href="https://www.instagram.com/marriottintl/" target="_blank" class="icon">
              <span>
                <inline-svg src="/images/social icons/Instagram.svg" width="45" height="45" fill="black"
                  aria-label="My image"></inline-svg>
              </span>
            </a>
            <a href="https://twitter.com/MarriottIntl" target="_blank" class="icon">
              <span>
                <inline-svg src="/images/social icons/Twitter.svg" width="45" height="45" fill="black"
                  aria-label="My image"></inline-svg>
              </span>
            </a>
            <a href="https://www.youtube.com/user/MarriottIntl" target="_blank" class="icon">
              <span>
                <inline-svg src="/images/social icons/YouTube.svg" width="45" height="45" fill="black"
                  aria-label="My image"></inline-svg>
              </span>
            </a>
            <a href="https://www.linkedin.com/company/marriott-international/ " target="_blank" class="icon">
              <span>
                <inline-svg src="/images/social icons/LinkedIn.svg" width="45" height="45" fill="black"
                  aria-label="My image"></inline-svg>
              </span>
            </a>
            <a href class="icon"> </a>
          </div>
        </div>
      </div>
      <div class="legal">
        <p> &copy; 1996 - 2022 Marriott International, Inc. All rights reserved. Marriott Proprietary Information </p>
        <div class="links">
          <button @click="terms = true">Terms</button>
          <a href="https://www.marriott.com/about/privacy.mi" target="_blank">Privacy Center</a>
          <a href="https://giving.marriott.com" target="_blank">Giving</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import MainHeader from "./components/MainHeader.vue";
import LetterModal from "./components/LetterModal.vue";
import AppHero from "./components/AppHero.vue";
import WriteLetter from "./components/WriteLetter.vue";
import InfoBlocks from "./components/InfoBlocks.vue";
import WallPapers from "./components/WallPapers.vue";
import TermsConditions from "./components/TermsConditions.vue";
export default {
  name: "App",
  components: {
    MainHeader,
    AppHero,
    WriteLetter,
    LetterModal,
    InfoBlocks,
    WallPapers,
    TermsConditions
  },
  data() {
    return {
      terms: false,
      modalIsOpen: false,
      logo: require("../public/images/Logos/Love Travel_White.svg"),
      heart: require("../public/images/Graphics/Rainbow Heart.png"),
    };
  },
  methods: {
    openModal() {
      this.modalIsOpen = true;
    },
    closeModal() {
      this.modalIsOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/main.scss";
@import "@/styles/base/colors.scss";
@import "@/styles/base/reset.scss";

#heart-image {
  width: 80px;
}

.inner {
  max-width: 1170px;
  margin: auto;
}

.gradient {
  background: linear-gradient($blue, $blue-purple, $purple, $peach);
  padding-top: 70px;
  padding-bottom: 100px;
  overflow: hidden;
}

.intro {
  margin: 0 30px;
  text-align: center;

  .heading1 {
    margin: 20px auto;
    max-width:500px;
  }

  img {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .intro {
    .heading2 {
      font-size: 24px;
      line-height: 30px;
    }
  }
}

@media (min-width: 768px) {
  .intro {
    padding: 100px 0;
    max-width: 60vw;
    margin: auto;
  }
}

footer {
  background: linear-gradient(to right,
      $blue 0%,
      $blue-purple 10%,
      $purple 40%,
      $peach 100%);
  padding: 60px 30px;

  .footer-logo {
    width: 250px;
  }

  .social-share-icons {
    margin-top: 40px;

    p {
      margin: 0 0 20px;
    }

    .social-icons {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    svg {
      path {
        fill: white;
        transition: all ease 0.25s;
      }
    }

    svg:hover {
      path {
        fill: black;
        transition: all ease 0.25s;
      }
    }
  }

  .legal {
    margin: 40px 0 0;
  }

  .links {
    margin-top: 10px;

    a, button {
      color:white;
      cursor:pointer;
      text-decoration: underline;
      margin-right: 20px;
      line-height: 1.5;
      transition: all ease 0.25s;

      &:hover {
        color: black;
      }
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 20px;

    .social-share-icons {
      width: 300px;
      text-align: left;
      margin-top: 0;
    }

    .flex {
      margin: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .legal {
      margin: 60px 30px 20px;
    }
  }
}
</style>
