/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLoveLetter = /* GraphQL */ `
  query GetLoveLetter($id: ID!) {
    getLoveLetter(id: $id) {
      id
      artSelection
      name
      loveLetter
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLoveLetters = /* GraphQL */ `
  query ListLoveLetters(
    $filter: ModelLoveLetterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoveLetters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        artSelection
        name
        loveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLoveLetters = /* GraphQL */ `
  query SyncLoveLetters(
    $filter: ModelLoveLetterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLoveLetters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        artSelection
        name
        loveLetter
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
