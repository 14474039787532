<template>
  <div
    v-if="modalIsOpen"
    class="overlay__container"
    :style="isSubmitted ? { display: 'flex' } : ''"
  >
    <div class="modal__wrapper">
      <div
        v-if="isSubmitted"
        class="modal modal--success"
        v-on:clickout="this.$emit('handleCloseModal')"
      >
        <span @click="this.$emit('handleCloseModal')">
          <svg
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
          >
            <path
              d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"
            />
          </svg>
        </span>
        <div class="success__copy">
          <h3 class="heading2">
            Thank you so much for joining us to help make love travel!
          </h3>
          <div class="p-regular">Share the Artwork</div>
          <div class="share">
            <a
              href="
                https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarriottloveletters.com%2F
              "
              target="_blank"
              class="icon"
            >
              <span>
                <inline-svg
                  src="/images/social icons/Facebook.svg"
                  width="45"
                  height="45"
                  fill="black"
                  aria-label="My image"
                ></inline-svg>
              </span>
            </a>
            <a
              class="icon twitter-share-button"
               href="https://twitter.com/intent/tweet?text=Marriott%20Love%20Letters%20%E2%99%A5&url=https%3A%2F%2Fmarriottloveletters.com%2F"
              target="_blank"
            >
            
              <span>
                <inline-svg
                  src="/images/social icons/Twitter.svg"
                  width="45"
                  height="45"
                  fill="black"
                  aria-label="My image"
                ></inline-svg>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div v-else class="modal" v-on:clickout="this.$emit('handleCloseModal')">
        <span @click="this.$emit('handleCloseModal')">
          <svg
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
          >
            <path
              d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"
            />
          </svg>
        </span>
        <div class="modal__content">
          <h3 class="heading2">Write Your Letter</h3>
          <p class="p-regular">
            Join us and make love travel. For every letter sent to LGBTQ+ youth, Marriott International will make a donation to our philanthropic partners, PFLAG National and The Matthew Shepard Foundation.
          </p>
          <h5 class="kicker heading3">Select Artwork</h5>
          <div class="modal__art">
            <ul class="modal__art--list">
              <li
                v-for="art in artSelections"
                @click="handleSelectArt(art)"
                :key="art.id"
              >
                <picture>
                  <source :srcset="art.src" media="(min-width: 768px)" />
                  <transition name="fade">
                    <img
                      :src="art.src"
                      alt="Love Letter Art"
                      class="fade"
                      :class="art.id === selectedArt.id ? 'active' : ''"
                    />
                  </transition>
                </picture>
              </li>
            </ul>
            <div class="modal__art--selected">
              <picture>
                <source :srcset="selectedArt.src" media="(min-width: 768px)" />
                <img :src="selectedArt.src" alt="Love Letter Art" />
              </picture>
            </div>
          </div>
          <form @submit.prevent="createLoveLetter" class="form heading3">
            <label class="kicker" for="Love Letter">Message</label>
            <textarea
              aria-label="Message"
              name="Love Letter"
              v-model="loveLetter"
              placeholder="Enter your message"
              rows="6"
            ></textarea>
            <span v-if="error" class="error">{{ error }}</span>
            <div class="form__checkbox-wrapper">
              <input type="checkbox" v-model="boxChecked" />
              <p class="legal">
                By clicking this box I agree to the terms and conditions
                <button @click="showTerms">Terms & Conditions</button>
              </p>
            </div>
            <div class="form__button">
              <button
                :disabled="!boxChecked"
                :class="
                  !boxChecked
                    ? 'button button--submit disabled'
                    : 'button button--submit'
                "
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <terms-conditions
          @clicked="terms = false"
          v-if="terms"
        ></terms-conditions>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";
import { createLoveLetter } from "../graphql/mutations";
import { listLoveLetters } from "../graphql/queries";
import TermsConditions from "./TermsConditions.vue";
import "clickout-event";

export default {
  props: {
    modalIsOpen: Boolean,
  },
  components: {
    TermsConditions,
  },
  mounted() {
    this.isSubmitted = false;
    this.loveLetter = "";
  },
  data() {
    return {
      terms: false,
      loveLetter: "",
      boxChecked: false,
      loveLetters: [],
      isSubmitted: false,
      error: "",
      artSelections: [
        {
          id: 1,
          name: "world",
          src: require("../../public/images/Postcard Art - Write Letter component/worldbackground.png"),
        },
        {
          id: 2,
          name: "hotel",
          src: require("../../public/images/Postcard Art - Write Letter component/hotelbackground.png"),
        },
        {
          id: 3,
          name: "polaroid",
          src: require("../../public/images/Postcard Art - Write Letter component/polaroidbackground.png"),
        },
        {
          id: 4,
          name: "map",
          src: require("../../public/images/Postcard Art - Write Letter component/mapbackground.png"),
        },
        {
          id: 5,
          name: "car",
          src: require("../../public/images/Postcard Art - Write Letter component/carbackground.png"),
        },
      ],
      selectedArt: {
        id: 1,
        name: "world",
        src: require("../../public/images/Postcard Art - Write Letter component/worldbackground.png"),
      },
    };
  },
  watch: {
    loveLetter: function () {
      if (this.loveLetter) {
        this.error = "";
      }
    },
    boxChecked: function () {
      this.boxChecked != this.boxChecked;
    },
  },
  methods: {
    showTerms(e) {
      e.preventDefault();
      this.terms = true;
    },
    handleSelectArt(art) {
      this.selectedArt = { ...art };
    },
    async createLoveLetter() {
      if (!this.loveLetter) {
        this.error = "Message field is required";
      } else {
        const { loveLetter, selectedArt } = this;
        const letter = { loveLetter, artSelection: selectedArt.id };
        this.loveLetters = [...this.loveLetters, letter];

        await API.graphql({
          query: createLoveLetter,
          variables: { input: letter },
        });

        this.loveLetter = "";
        this.isSubmitted = true;
      }
    },
    async listLoveLetters() {
      const { data } = await API.graphql({
        query: listLoveLetters,
      });
      console.log(data);
      this.loveLetters = data.listLoveLetters.items;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/base/colors.scss";

.overlay__container {
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  overflow: auto;
  .modal__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
    height: auto;
    width: 100%;
  }
  .success__copy {
    display: flex;
    flex-direction: column;
    padding: 40px;
    text-align: center;
    gap: 1.25rem;
  }
  button {
    cursor: pointer;
  }
  .modal {
    position: relative;
    background: linear-gradient(
      $blue 0%,
      $blue-purple 10%,
      $purple 40%,
      $peach 100%
    );
    padding: 25px;
    height: auto;
    border-radius: 1rem;
    transition: all ease 0.25s;
    @media (min-width: 550px) {
      width: 55rem;
    }
    span {
      display: flex;
      justify-content: flex-end;
      float: right;
      width: fit-content;
      cursor: pointer;
    }
    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem 10px;

      h3 {
        font-weight: bold;
      }
      @media (min-width: 550px) {
        padding: 2rem 25px;
      }
    }
    .modal__art {
      display: flex;
      gap: 2.5rem;
      &--selected {
        display: none;
        img {
          max-width: 750px;
          border: 8px solid;
        }
        @media (min-width: 768px) {
          display: block;
        }
      }
      &--list {
        display: flex;
        gap: 1.25rem;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style-type: none;
        @media (min-width: 768px) {
          flex-direction: column;
        }
        li {
          cursor: pointer;
          img {
            width: 130px;
            transition: all 0.25s ease;
            @media (min-width: 768px) {
              width: 75px;
            }
            &:hover {
              transform: scale(1.15);
              border: 3px solid;
            }
          }
        }

        .active {
          transform: scale(1.15);
          border: 3px solid;
        }
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .error {
        color: red;
        background-color: lightpink;
        padding: 0.35rem;
        border-radius: 0.5rem;
        border: 1px solid red;
        cursor: unset;
      }

      &__checkbox-wrapper {
        display: flex;
        gap: 0.75rem;
        margin: 1rem 0;

        input {
          margin: 0 -.5rem;
          width: 30px;
          height: 16.5px;
        }
      }
    }
    input,
    textarea {
      background-color: #fff;
      border-radius: 0.25rem;
      border: 1px solid #fff;
      padding: 0.5rem;
      font-size: 16px;
      font-family: inherit;
      box-sizing: border-box;
      outline: none;
      transition: ease 0.5s;
      -webkit-transition: ease 0.5s;
    }
    textarea:focus {
      border: 1px solid $blue;
    }
    ::placeholder {
      color: #000;
      opacity: 0.35;
    }
    label {
      text-transform: uppercase;
    }
    .kicker {
      text-transform: uppercase;
    }
    .form__button {
      display: flex;
      justify-content: center;
      .button--submit {
        padding: 16px 50px;
        text-transform: uppercase;
        font-size: 19px;
      }

      .disabled {
        background-color: lightgray;
        color: gray;
        border: 2px solid lightgray;
        cursor: unset;
      }
    }
  }
}

.fade {
  transition: all ease 0.25s;
}

/*Vue JS CSS Components*/
.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-enter {
  transition: opacity 0.5s ease;
}

.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-leave-to,
.fade-enter-from

/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.share {
  display: flex;
  justify-content: center;
}
.icon {
  margin: 0 10px;
}
svg {
  fill: white;
  transition: all ease 0.25s;
}

svg:hover {
  fill: black;
  transition: all ease 0.25s;
}
</style>
