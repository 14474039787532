<template>
  <section class="wallpapers">
    <div class="wallpapers__inner">
      <div class="wallpapers__info">
        <p class="heading1">Wallpaper Positivity</p>
        <p class="p-large">
          Bring Meg's positive vibes wherever you go, with these custom
          wallpapers for any device.
        </p>
      </div>
      <div
        class="wallpaper--selected"
        :style="{ backgroundColor: selectedArt.background }"
      >
        <img :src="background" alt="Background" />
        <img :src="selectedArt.src" alt="Love Letter Art" />
      </div>
      <div class="wallpaper-thumbnails">
        <ul class="wallpaper-selections">
          <li
            class="selection"
            v-for="art in mobileSelections"
            @click="handleSelectArt(art)"
            :key="art.id"
            :style="{ backgroundColor: art.background }"
            :class="art.id === selectedArt.id ? 'active' : ''"
          >
            <picture>
              <source :srcset="art.src" media="(min-width: 768px)" />

              <img
                :src="art.src"
                alt="Love Letter Art"
                class="fade"
                
              />
            </picture>
          </li>
        </ul>
      </div>
      <div class="download">
        <p class="p-regular">Select Artwork and Download</p>
        <div class="buttons">
          <a :href="selectedArt.src" download class="button light">Phone</a
          >
          <a :href="desktopSelections[selectedArt.id - 1].src" download class="button light">Desktop</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      media: "mobile",

      background: require("../../public/images/mobile-wallpaper.png"),
      desktopSelections: [
        {
          id: 1,
          src: require("../../public/images/Wallpapers_Desktop/Car_Wallpaper_Desktop.png"),
          background: "#e8d7fe",
        },
        {
          id: 2,
          src: require("../../public/images/Wallpapers_Desktop/Hotel_Wallpaper_Desktop.png"),
          background: "#c6edfe",
        },
        {
          id: 3,
          src: require("../../public/images/Wallpapers_Desktop/Map_Wallpaper_Desktop.png"),
          background: "#b9f7f0",
        },
        {
          id: 4,
          src: require("../../public/images/Wallpapers_Desktop/Polaroid_Wallpaper_Desktop.png"),
          background: "#dba7e9",
        },
        {
          id: 5,
          src: require("../../public/images/Wallpapers_Desktop/World_Wallpaper_Desktop.png"),
          background: "#a8f1fe",
        },
      ],
      mobileSelections: [
        {
          id: 1,
          src: require("../../public/images/Wallpapers_Mobile/Car_Wallpaper_Phone.png"),
          background: "#e8d7fe",
        },
        {
          id: 2,
          src: require("../../public/images/Wallpapers_Mobile/Hotel_Wallpaper_Phone.png"),
          background: "#c6edfe",
        },
        {
          id: 3,
          src: require("../../public/images/Wallpapers_Mobile/Map_Wallpaper_Phone.png"),
          background: "#b9f7f0",
        },
        {
          id: 4,
          src: require("../../public/images/Wallpapers_Mobile/Polaroid_Wallpaper_Phone.png"),
          background: "#dba7e9",
        },
        {
          id: 5,
          src: require("../../public/images/Wallpapers_Mobile/World_Wallpaper_Phone.png"),
          background: "#a8f1fe",
        },
      ],
      selectedArt: {
        id: 1,
        src: require("../../public/images/Wallpapers_Mobile/Car_Wallpaper_Phone.png"),
        background: "#e8d7fe",
      },
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
  },
  methods: {
    handleSelectArt(art) {
      this.selectedArt = { ...art };
      console.log(this.selectedArt.background);
    },
  },
};
</script>

<style scoped lang="scss">
.wallpapers {
  background: #9867c6;
  padding: 80px 30px;
}

.wallpapers__inner {
  max-width: 1170px;
  margin: auto;
}

.heading1 {
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  gap: 30px;
}
.wallpaper--selected {
  width:60%;
  margin:20px auto;
  &:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 5px;
    width: 100%;
    background-color: #9867c6;
  }
  position: relative;
  img:first-child {
    position: relative;
    z-index: 2;
  }
  img:last-child {
    position: absolute;
    z-index: 1;
    top: 47%;
    left: 66%;
    width: 65%;
    transform: rotateZ(-10deg) translate(-50%, -50%);
  }
}
.wallpaper-selections {
  display: flex;
  gap: 20px;
  width: 90%;
  padding: 0;
  margin: 20px 0;
}
.selection {
  transition: all ease .25s;
  cursor: pointer;
  width: 100%;
  height: 0;
  position: relative;
  padding-top: calc(100% / 5 - 16px);

  img {
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
  }
}

.active {
 width: calc(100% - 3px);
padding-top: calc(20% - 20px);
  border: 3px solid;
}

@media (min-width:830px) {
  .wallpapers {
     max-height:700px;
    overflow: hidden;
  }
  .wallpapers__inner {
   
    display:grid;
    grid-template-columns: 1fr 15% 1fr;
  }

  .wallpapers__info {
    grid-column: 3/4;
    grid-row: 2/3;
  }
  .wallpaper-thumbnails {
    grid-column: 3/4;
    grid-row: 3/4;
  }
  .download {
    grid-column: 3/4;
    grid-row: 4/5
  }

  .wallpaper--selected {
    grid-column: 1/2;
    grid-row: 1/15;
    width:100%;

  }
}

@media (min-width:1000px) {
  .wallpaper--selected {
    &:after {
      height:40px;
    }
    img:last-child {
      left: 61%;
      top: 35%;
    }
  }
}
</style>
