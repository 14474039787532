import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

import { createApp } from 'vue'
import App from './App.vue'
import AppHero from "./components/AppHero.vue";
import WriteLetter from "./components/WriteLetter.vue";
import InfoBlocks from "./components/InfoBlocks.vue";
import InlineSvg from 'vue-inline-svg';
import 'clickout-event';

export {
    AppHero,
    WriteLetter,
    InfoBlocks
}
const app = createApp(App);


Amplify.configure(awsconfig);

app.component('inline-svg', InlineSvg);
app.mount('#app');
