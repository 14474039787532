<template>
  <div class="terms-conditions">
    <button @click="close" class="button black">Close</button>
    <div class="heading2">
      MARRIOTT INTERNATIONAL, INC. MARRIOTT LOVE LETTERS MICROSITE CONTENT
      SUBMISSION TERMS OF USE
    </div>
    <p>
      These “Terms of Use” govern your access to and use of Marriott
      International, Inc.’s Marriott Love Letters Microsite (“the Microsite”),
      including uploading written letters (Content) to the Microsite. By
      uploading Content to the Microsite, you are entering into an agreement
      with Marriott International, Inc. (“Marriott”) to accept all of these
      Terms of Use as they may be updated by Marriott without notice. Marriott
      recommends that you review these Terms of Use from time to time to ensure
      that you are familiar with the most recent version. You agree that all
      Content that you submit to the Microsite shall become the sole and
      exclusive property of Marriott. If by operation of law or any other reason
      any of the Content, including all related intellectual property rights, is
      not deemed to be a work for hire or is not otherwise owned in its entirety
      by Marriott automatically upon creation thereof, then you hereby assign to
      Marriott, without additional consideration, all right, title, and interest
      in and to such Content, including all related intellectual property
      rights. To the extent, if any, that this Paragraph does not provide
      Marriott with full ownership, right, title, and interest throughout the
      world in and to the Content, you irrevocably agree to grant, and do hereby
      grant, Marriott an exclusive, perpetual, irrevocable, transferable,
      unlimited, fully paid-up, royalty-free, worldwide license to make, have
      made, use, reproduce, market, modify, make derivative works from, publicly
      perform, publicly display, offer to sell, sell or otherwise exploit such
      Content, with the right to sublicense each and every such right. To the
      extent your moral rights, if any, in the Content cannot be assigned, you
      hereby waive, to the fullest extent permitted by law in any jurisdiction
      where moral rights exist, any and all moral rights that you may have in
      any Content and consent to any action of Marriott that would violate such
      moral rights in the absence of such consent.
    </p>
    <p></p>
    <p>
      You release and discharge Marriott from any and all liability arising out
      of or relating to the Content and Marriott’s use of the Content, including
      without limitation, claims for defamation or claims based on the rights of
      privacy or publicity or on any blurring or visual distortion or
      alteration, whether intentional or otherwise, that may occur or be
      produced in the use of the Content. For all Content you upload to the
      Microsite, you represent and warrant that:
    </p>
    <p></p>
    <ul>
      <li>
        (a) you have all rights necessary for you to assign the Content to
        Marriott;
      </li>
      <li>
        (b) all individuals appearing in the Content have given their consent to
        Marriott’s use of the Content for any lawful purpose;
      </li>
      <li>
        (c) you are the sole author of and owner of the intellectual property
        rights in the Content and you shall be fully responsible for such
        Content;
      </li>
      <li>
        (d) you are at least the age of majority in the jurisdiction in which
        you reside;
      </li>

      <li>(e) the Content does not contain any confidential information;</li>
      <li>(f) the Content is not false, inaccurate or misleading;</li>
      <li>
        (g) the Content does not contain personally identifiable information of
        any person including yourself (including any Marriott representative),
        including first and last name, username containing first and last name,
        email address, physical address, or phone number;
      </li>
      <li>
        (h) the Content, and Marriott’s use of the Content, does not infringe
        any third party's copyright, patent, trademark, trade secret or other
        proprietary rights or rights of publicity or privacy, or otherwise
        violate the rights of any third party;
      </li>
      <li>
        (i) the Content, and Marriott’s use of the Content, does not violate any
        law, statute, ordinance or regulation;
      </li>
      <li>
        (j) the Content, and Marriott’s use of the Content, is not, and may not
        reasonably be considered to be, obscene, abusive, threatening, indecent,
        defamatory, libelous, hateful, racially or religiously biased or
        otherwise offensive;
      </li>
      <li>
        (k) the Content does not contain advertisements, "SPAM" or references to
        other services, products, offers or websites; and
      </li>
      <li>
        (l) the Content does not contain any computer viruses, worms or other
        potentially damaging computer programs or files.
      </li>
    </ul>
    <p>
      You agree to indemnify and hold Marriott and its affiliates harmless from
      all claims, demands, and damages (actual and consequential) of every kind
      and nature, known and unknown, including reasonable attorneys’ fees,
      arising out of a breach of your representations and warranties set forth
      above, your violation of any law or the rights of a third party, or your
      submission of Content that violates these Terms of Use. Marriott reserves
      the right, it its expense, to assume exclusive defense of any third-party
      claim and all negotiations for settlement and you agree to cooperate with
      Marriott in the defense of any such claim, at Marriott’s request. Any
      waiver by Marriott with respect to a breach by you or others shall not be
      construed to be a waiver as to succeeding breaches.
    </p>
    <p>
      Marriott and/or its affiliates shall not be liable for any damages or
      injury, including any indirect, special, incidental, punitive, exemplary,
      consequential or any other damages whatsoever arising out of or in
      connection with your submission of Content or Marriott’s use of such
      Content.
    </p>
    <p>
      These Terms of Use and their enforcement shall be governed by the laws of
      the State of Maryland, without reference to the principles of conflicts of
      law thereof or such principles of any other jurisdiction.
    </p>
    <p>
      It is Marriott’s policy to respect the intellectual property rights of
      others. In connection with this policy, Marriott will not knowingly post
      Content of any kind that infringes the copyright or other intellectual
      property rights of others. As such, and pursuant to the Digital Millennium
      Copyright Act (17 U.S.C. §512(c)(2)), Marriott has designated an agent to
      receive notifications of alleged copyright infringement of the Content
      posted by you. If you believe that your work has been used in a way that
      constitutes copyright infringement, please send: (a) a description of the
      work; (b) a description of the location of the posted work, including the
      URL; (c) your name, address, and telephone number; (d) a signed statement
      by you that you have a good faith belief that the disputed use is
      unauthorized under current law; and (e) a signed statement by you that you
      believe, under penalty of perjury, that your allegations are true and that
      you are the copyright owner or acting on behalf of the copyright owner.
      Please submit these materials to:
    </p>
    <p>Senior Counsel, Intellectual Property
    <br>Marriott International, Inc.
    <br>10400 Fernwood Road, Dept. 52/923
    <br>Bethesda, MD 20817
    <br>e-mail: IPLaw@marriott.com
    </p>
    <div class="p-regular">
      If you have questions about these Terms of Use, please contact Marriott
      via e-mail mzych@160over90.com
    </div>
  </div>
</template>

<script>
export default {

  methods: {
    close(event) {
      event.preventDefault()
         this.$emit('clicked', false)
     }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/base/colors.scss";

.terms-conditions {
  padding: 25px;
    height: auto;
    transition: all ease 0.25s;
    position: fixed;
    top: 0;
    left: 0;
    overflow:scroll;
    background: white;
    color: black;
    z-index: 9999;
    height:100vh;

    .heading2 {
      margin-bottom:10px;
      margin-top: 60px;
      @media (min-width:768px) {
        margin-top:22px
      }
    }

    p, li {
       user-select: none;
      font-family: 'Swiss721BT-Regular', sans-serif;
    font-size: 16px;
     line-height: 24px;
     margin-bottom:5px;
    }
    .button {
      position: sticky;
    top: 0;
    background: white;
    float: right;
    }
}
</style>
