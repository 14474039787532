<template>
  <div class="write-letter">
    <div class="write-letter__content">
      <div class="heading1">Write Your Letter</div>
      <p class="p-large">
        Share your message of love and support, paired with a custom postcard
        design by artist, Meg Emiko. Letters will be printed and distributed to
        LGBTQ+ youth by our philanthropic partners PFLAG and True Colors United.
      </p>
      <button
        @click="this.$emit('handleOpenModal')"
        v-show="!isMobile"
        class="button desktop-only"
      >
        Send your Love
      </button>
    </div>
    <div class="write-letter__postcard-examples">
      <div class="postcard-examples">
        <div class="postcard-letter-example">
          <div class="postcards">
            <transition name="fade" mode="out-in">
              <div v-show="letter === 1" class="postcard" id="postcard-1">
                <picture>
                  <source :srcset="postcard1" media="(min-width: 768px)" />
                  <img :src="postcard1" alt="love letters postcard 1" />
                </picture>
              </div>
            </transition>
            <transition name="fade" mode="out-in">
              <div v-show="letter === 2" class="postcard" id="postcard-2">
                <picture>
                  <source :srcset="postcard2" media="(min-width: 768px)" />
                  <img :src="postcard2" alt="love letters postcard 1" />
                </picture>
              </div>
            </transition>
            <transition name="fade" mode="out-in">
              <div v-show="letter === 3" class="postcard" id="postcard-3">
                <picture>
                  <source :srcset="postcard3" media="(min-width: 768px)" />
                  <img :src="postcard3" alt="love letters postcard 1" />
                </picture>
              </div>
            </transition>
            <transition name="fade" mode="out-in">
              <div v-show="letter === 4" class="postcard" id="postcard-4">
                <picture>
                  <source :srcset="postcard4" media="(min-width: 768px)" />
                  <img :src="postcard4" alt="love letters postcard 1" />
                </picture>
              </div>
            </transition>
            <transition name="fade" mode="out-in">
              <div v-show="letter === 5" class="postcard" id="postcard-5">
                <picture>
                  <source :srcset="postcard5" media="(min-width: 768px)" />
                  <img :src="postcard5" alt="love letters postcard 1" />
                </picture>
              </div>
            </transition>
          </div>
          <div class="letter">
            <transition name="fade">
              <div v-show="letter === 1" class="" id="letter-5">
                <p class="p-regular">
                  I want you to know that you will always have a friend rooting
                  for you from St Louis, Missouri.
                </p>
              </div>
            </transition>
            <transition name="fade">
              <div v-show="letter === 2" class="" id="letter-5">
                <p class="p-regular">I am proud of you always!</p>
              </div>
            </transition>
            <transition name="fade">
              <div v-show="letter === 3" class="" id="letter-5">
                <p class="p-regular">
                  I support you and want to do my part to make this world a
                  better place for you.
                </p>
              </div>
            </transition>
            <transition name="fade">
              <div v-show="letter === 4" class="" id="letter-5">
                <p class="p-regular">Sending love, happy Pride Month!</p>
              </div>
            </transition>
            <transition name="fade">
              <div v-show="letter === 5" class="" id="letter-5">
                <p class="p-regular">
                  Whomever you are, whomever you love. Wherever you go, you are
                  enough. And you are always welcome here.
                </p>
              </div>
            </transition>
          </div>
        </div>
        <div class="buttons">
          <button
            class="carousel-button"
            aria-label="Example Postcard 1"
            :class="letter === 1 ? 'active' : ''"
            @click="changeLetter(1)"
          >
            <span class="inner"></span>
          </button>
          <button
            class="carousel-button"
            aria-label="Example Postcard 2"
            :class="letter === 2 ? 'active' : ''"
            @click="changeLetter(2)"
          >
            <span class="inner"></span>
          </button>
          <button
            class="carousel-button"
            aria-label="Example Postcard 3"
            :class="letter === 3 ? 'active' : ''"
            @click="changeLetter(3)"
          >
            <span class="inner"></span>
          </button>
          <button
            class="carousel-button"
            aria-label="Example Postcard 4"
            :class="letter === 4 ? 'active' : ''"
            @click="changeLetter(4)"
          >
            <span class="inner"></span>
          </button>
          <button
            class="carousel-button"
            aria-label="Example Postcard 5"
            :class="letter === 5 ? 'active' : ''"
            @click="changeLetter(5)"
          >
            <span class="inner"></span>
          </button>
        </div>
        <button
          @click="this.$emit('handleOpenModal')"
          v-show="isMobile"
          class="button mobile-only"
        >
          Send your love
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WriteLetter",
  props: {
    msg: String,
  },
  data() {
    return {
      letter: 1,
      isMobile: false,
      postcard1: require("../../public/images/Postcard Art - Write Letter component/worldbackground.png"),
      postcard2: require("../../public/images/Postcard Art - Write Letter component/hotelbackground.png"),
      postcard3: require("../../public/images/Postcard Art - Write Letter component/polaroidbackground.png"),
      postcard4: require("../../public/images/Postcard Art - Write Letter component/mapbackground.png"),
      postcard5: require("../../public/images/Postcard Art - Write Letter component/carbackground.png"),
      interval: null,
    };
  },
  mounted() {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    }

    this.interval = setInterval(() => {
      if (this.letter < 5) {
        this.changeLetter(this.letter + 1);
      } else {
        this.changeLetter(1);
      }
    }, 5000);

    window.addEventListener("resize", function () {
      console.log("resize");
      if (window.innerWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    changeLetter(number) {
      this.letter = number;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.button.mobile-only {
  margin-left: auto;
  margin-right: auto;
}
.carousel-button {
  cursor: pointer;
  padding: 2px;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  display: flex;
  margin: 0 10px;
  align-items: center;
  justify-self: center;

  .inner {
    border-radius: 50%;
    background: white;
    width: 15px;
    height: 15px;
    display: inline-block;
  }

  &.active {
    border: 1px solid white;
  }
}

.postcards {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.write-letter {
  margin: 100px 30px;

  &__content {
    text-align: center;

    p {
      margin: 20px 0;
    }
  }
}

.postcards {
  width: 100%;
  height: 0;
  padding: relative;
  position: relative;
  padding-top: 75%;
  flex: 1;
}

.postcard-letter-example {
  display: flex;
  background: white;
  color: black;
  gap: 30px;
  align-items: center;
  padding: 20px;

  .postcard {
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    &#postcard-1 {
      background: #a8f1fe;
    }
    &#postcard-2 {
      background: #c6edfe;
    }
    &#postcard-3 {
      background: #dba7e9;
    }
    &#postcard-4 {
      background: #b9f7f0;
    }
    &#postcard-5 {
      background: #e8d7fe;
    }
  }

  .letter {
    flex: 1;
    position: relative;
    > div {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.buttons {
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

@media (max-width: 1023px) and (min-width: 530px) {
  .postcard-letter-example {
    max-width: 400px;
    margin: auto;
  }

  button {
    margin: 20px auto;
  }
}

@media (min-width: 1024px) {
  .write-letter {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 140px 30px;
    &__content {
      text-align: left;
    }
  }

  .write-letter__postcard-examples {
    width: 50%;
    margin-bottom: -40px;
  }

  .write-letter__content {
    width: 50%;
    margin: 0 50px;
  }
}

/*Vue JS CSS Components*/
.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-enter {
  transition: opacity 0.5s ease;
}

.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-leave-to,
.fade-enter-from

/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
